<script lang="ts" setup>
import { computed } from 'vue';

import InfoModal from '@exchange/libs/modals/src/lib/common/InfoModal.vue';

type Action = {
  action: string;
  actionFn: () => void;
  actionTestId: string;
};

type Actions = [Action, Action, Action] | [Action, Action];

const props = defineProps<{
  canNotifyMe: boolean;
  hasOptedToBeNotified: boolean;
}>();

const emit = defineEmits<{
  (e: 'contact-support'): void;
  (e: 'logout'): void;
  (e: 'notify-me'): void;
}>();

const contactSupportAction: Action = {
  action: 'ui.forbiddenJurisdictionModal.secondaryAction',
  actionFn: () => emit('contact-support'),
  actionTestId: 'contact-support',
};

const logoutAction: Action = {
  action: 'modules.auth.logout',
  actionFn: () => emit('logout'),
  actionTestId: 'logout',
};

const notifyMeAction: Action = {
  action: 'ui.forbiddenJurisdictionModal.primaryAction',
  actionFn: () => emit('notify-me'),
  actionTestId: 'notify-me',
};

const actions = computed((): Actions => (props.canNotifyMe ? [notifyMeAction, contactSupportAction, logoutAction] : [contactSupportAction, logoutAction]));
</script>

<template>
  <info-modal
    testid="forbidden-jurisdiction-modal"
    content-test-id="forbidden-jurisdiction-modal-content"
    variant="dark"
    :no-icon="true"
    :wide="actions.length > 2"
    :title="'ui.forbiddenJurisdictionModal.title'"
    :message="'ui.forbiddenJurisdictionModal.message'"
    :action="actions[0].action"
    :action-disabled="props.hasOptedToBeNotified"
    :action-test-id="actions[0].actionTestId"
    :action-secondary="actions[1]?.action"
    :action-secondary-test-id="actions[1]?.actionTestId"
    :action-tertiary="actions[2]?.action"
    :action-tertiary-test-id="actions[2]?.actionTestId"
    @action-clicked="actions[0].actionFn"
    @action-secondary-clicked="actions[1]?.actionFn"
    @action-tertiary-clicked="actions[2]?.actionFn"
  />
</template>
